//rafce
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

import { Link } from "gatsby"
import { BiArrowBack } from "react-icons/bi"

const All_Amazon_Marketplaces_In_One_Dashboard = () => {
  return (
    <Layout>
      <Seo
        title="All Amazon Marketplaces In One Dashboard"
        description="combines amazon marketplaces into one dashboard making it easy and efficient to navigate and get the job done faster"
      />
      <Container fluid className="px-0">
        <div className="text-center " style={{ position: "relative" }}>
          <Styledh1 className="display-4">
            All Amazon Marketplaces In One Dashboard
          </Styledh1>
          <StaticImage
            className="mt-2"
            src="../../assets/images/featuresbg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <Row className="text-center">
                <Col sm={12} className="pb-5">
                  <StaticImage
                    src="../../assets/images/azszero-dashboard.jpg"
                    alt="Amazon App Dashboard"
                    placeholder="tracedSVG"
                    layout="constrained"
                    //  aspectRatio={1 / 1}
                    //  height="600"
                  ></StaticImage>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8} className="pb-5">
                  <ul>
                    <li>
                      Save Time without navigating to each market checking on
                      sales, inventory, feedbacks, etc.
                    </li>
                    <li>
                      azszero will pull all your data and generate live reports
                      that are easy to understand.
                    </li>
                    <li>
                      You can sort and filter your sales and products per
                      market.
                    </li>
                    <li>
                      Each action azszero gives you will link to the exact
                      market with an exact link for you to take the proper
                      action.
                    </li>
                    <li>
                      You will have a broader picture of what is going on across
                      all markets.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/Amazon_FBA_Seller_App_Features">
                <BiArrowBack />
                &nbsp;&nbsp;Back to Features Page
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`

export default All_Amazon_Marketplaces_In_One_Dashboard
